import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";

const PeoplePanelSection = ({ testimonials, resources, title }) => {
  const parse = require("html-react-parser");

  return (
    <div className="expert">
        {testimonials &&
          testimonials.map((testimonial) => {
            /* NOSONAR */
            return (
              <div className="expert__container" key={testimonial.title} async>
                <div className="expert__vedge bg-gray"></div>
                <div className="expert__img">
                  <img
                    src={testimonial.image}
                    alt="Author image"
                    width="260px"
                  />
                </div>
                <div className="expert__content flow">
                  <h2>
                    <StaticResourceText
                      resources={resources}
                      reskey={title}
                    ></StaticResourceText>
                  </h2>
                  <div className="" async>{testimonial.shortDesc && parse(testimonial.shortDesc)}</div>
                  <p className="mb0 color-primary">
                    - {testimonial.title}, {testimonial.designation}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
  );
};
export default PeoplePanelSection;

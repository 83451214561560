import React from "react";

const AbTriangle = () => {
  return (
    <div async>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="hero__overlap"
        viewBox="0 0 1920 913.533"
      >
        <g transform="translate(0 113.533)">
          <path
            className="hardLight"
            d="M1920,499.9,349.5,800,1920-113.533V499.9Z"
            fill="#b9160b"
            opacity="0.5"
          />
          <path
            className="multiply"
            d="M350,800,0,700V-99.844L773.27,719.02Z"
            fill="#670000"
            opacity="0.6"
          />
          <path
            className="lighten"
            d="M772.38,719.19,349.5,800,121.71,270.27Z"
            fill="#ffbf3f"
            opacity="0.35"
          />
        </g>
      </svg>
    </div>
  );
};
export default AbTriangle;

import React, {  } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
const PeopleCarouselSection = ({ title, icons, contents }) => {
  /* NOSONAR */

  const parse = require("html-react-parser");
  return (
    <div className=" bg-white" async>
      <section className="container">
        <div className="container__title">
          <h2 className="align-center">{title}</h2>
        </div>
        <div className="holder-carousel">
          <Swiper
            modules={[Pagination, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {contents &&
              contents.map((content) => {
                return (
                  <SwiperSlide key={content.title}>
                    <div className="section-carousel">
                      <div className="carousel__content flow section">
                        {parse(content.shortDesc)}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>
    </div>
  );
};
export default PeopleCarouselSection;

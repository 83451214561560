const ApiWithForm = async (requestData, endpoint) => 
{

  let formData = new FormData();
    Object.keys(requestData).forEach(function (key) {
        formData.append(key, requestData[key]);
    });

  const options = {
    method: "POST",
    headers: {
      
    },
    body: formData,
  };

  const apiRes = await fetch(endpoint, options);
  if (apiRes.status == 200) {
    return await apiRes.json();
  } else if (apiRes.status == 204) {
    return true;
  } else {
    return null;
  }
};
export default ApiWithForm;

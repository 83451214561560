
import React from "react";
import { Helmet } from "react-helmet";

const Parallax = (props) => {
  if (props.pageheroimage) {
    return (
        <Helmet>
        <style>{`

                        .webp .pin {
                            width: 100%;
                            height: 100%;
                            background-image: url(${props.pageheroimage});
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: top center;
                        }

                        .no-webp .pin {
                            width: 100%;
                            height: 100%;
                            background-image: url(${props.pageheroimage});
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: top center;
                        }

                `}</style>
                </Helmet>
    );
  }
  if (props.pageFixedImage) {
    return (
        <Helmet>
        <style>{`

                      .webp .pin2 {
                          width: 100%;
                          height: 100%;
                          background-image: url(${props.pageFixedImage});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

                      .no-webp .pin2 {
                          width: 100%;
                          height: 100%;
                          background-image: url(${props.pageFixedImage});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

              `}</style>
              </Helmet>
    );
  }
  if (props.pageFixedImage1) {
    return (
        <Helmet>
        <style>{`

                      .webp .pin3{
                          width: 100%;
                          height: 100%;
                          background-image: url(${props.pageFixedImage1});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

                      .no-webp .pin3 {
                          width: 100%;
                          height: 100%;
                          background-image: url(${props.pageFixedImage1});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

              `}</style>
              </Helmet>
    );
  }
  if (props.pagePanelBgImage2) {
    return (
        <Helmet>
        <style>{`

                      .no-webp .bg2{
                          background-image: url(${props.pagePanelBgImage2});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

                      .webp .bg2{
                          background-image: url(${props.pagePanelBgImage2});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

              `}</style>
              </Helmet>
    );
  }
  if (props.pagePanelBgImage1) {
    return (
        <Helmet>
        <style>{`
                      .no-webp .bg1{
                          background-image: url(${props.pagePanelBgImage1});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

                      .webp .bg1{
                          background-image: url(${props.pagePanelBgImage1});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

              `}</style>
              </Helmet>
    );
  }
  if (props.blogImage) {
    return (
        <Helmet>
        <style>{`

                      .webp .pin0 {
                          width: 100%;
                          height: 100%;
                          background-image: url(${props.blogImage});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: top center;
                      }

                      .no-webp .pin0 {
                          width: 100%;
                          height: 100%;
                          background-image: url(${props.blogImage});
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: top center;
                      }

              `}</style>
              </Helmet>
    );
  } else if (props.csImage) {
    return (
        <Helmet>
        <style>{`

                  .webp .pin0 {
                      width: 100%;
                      height: 100%;
                      background-image: url(${props.csImage});
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: top center;
                  }

                  .no-webp .pin0 {
                      width: 100%;
                      height: 100%;
                      background-image: url(${props.csImage});
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: top center;
                   }
              `}</style>
              </Helmet>
    );
  } else if (props.banner3) {
    return (
  <Helmet>
        <style>{`

                      .no-webp .sub_sub_section{
                          background-image: url(${props.banner3}); 
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }

                      .webp .sub_sub_section{
                          background-image: url(${props.banner3}); 
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center center;
                      }
              `}</style>
              </Helmet>
    );
  }
};
export default Parallax;

import {scrollRefresh} from './parallax'
export default function faq(){
     
     let faqs = Array.from(document.querySelectorAll('.faq'));

     function removeActive(ele){
          for(let faqContent of faqs){
               if(ele !== faqContent && faqContent.classList.contains('active')){
                    faqContent.classList.remove('active');
               }
          }
     }
     
     for(let faqContent of faqs){
          faqContent.addEventListener('click', function(e) {
               e.preventDefault();
               removeActive(faqContent);
               this.classList.toggle('active');
               scrollRefresh();
          });
     }  
}
import { removeMobileMenu } from "./navigation";
import { scrollRefresh } from "./parallax";

const myTimeout = setTimeout(pageHeightHandler, 8000);

function pageHeightHandler() {
 
          const element = document.querySelector('body'); 
          let pageHieght = element.style.height; 
          console.log(pageHieght);
          localStorage.setItem("height", pageHieght);
     
  }




window.addEventListener("scroll", () => {

  const scrollposition = document.querySelector('#smooth-content');

  const bgimagepostion = document.querySelector('.pin');

  const body = document.querySelector('body');

  if (window.scrollY === 0 && window.innerWidth > 2560) {
    scrollposition?.classList?.add('scrollPoistionTop');
    bgimagepostion?.classList?.add('scrollPoistionTopfprbgimage');
  } else {
    scrollposition?.classList?.remove('scrollPoistionTop');
    bgimagepostion?.classList?.remove('scrollPoistionTopfprbgimage');
  }

  const scrollBottomPosition = window.scrollY;


  let style = document.createElement('style');
  style.innerHTML = `
  .scrollPoistionBotom{
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -${scrollBottomPosition}, 0, 1) !important;
    transition: all .8s;
  }
  `;


  if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && window.innerWidth > 2560) {

   
    console.log(window.scrollY);

    document.head.appendChild(style);


    scrollposition.classList.add('scrollPoistionBotom');
  
}
else{
  scrollposition.classList.remove('scrollPoistionBotom');
}
// let scrollPosition = window.scrollY;
//     console.log(scrollPosition);

 });



export default function CommonFunc(page)
{
  if(page!=null)
  { 
    
    if (!window.location.href.includes("/blogs/") &&!window.location.href.includes("/casestudies/")) 
    {
      localStorage.setItem("ancestor", window.location.href);
    }
    var element=document.querySelector(".PgFixedImg");
    if(page.backgroundImage!=null)
    {
        element.classList.add("pin2")
    }
    else{
        element.classList.remove("pin2")
    }
  }
    // const interval = setTimeout(() => {
    //   scrollRefresh();
    //   clearTimeout(interval);
    // }, 5000);
    const interval1 = setTimeout(() => {
      var event = new Event('resize');
      window.dispatchEvent(event);
    }, 13000);

     const interval = setTimeout(() => {
       removeMobileMenu();
      clearTimeout(interval);
    }, 1000);
    const interva2 = setTimeout(() => {
      removeMobileMenu();
      clearTimeout(interva2);
    }, 2000);
    const interva3 = setTimeout(() => {
      removeMobileMenu();
      clearTimeout(interva3);
    }, 3000);

   
    
}


import React, { useState ,useEffect} from 'react';
import CommonObj from '../CommonObj';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import faq  from '../../assets/js/Components/faq';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import parallax ,{ scrollRefresh }from '../../assets/js/Components/parallax';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import formInit from '../../assets/js/Components/formInit';
import Parallax from '../Common/Parallax';
import Hero from '../Common/Hero';
import TestimonialExcerptSection from '../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../Common/TestimonialTemplate';
import BlogTemplate  from '../Common/blog-template';
import FAQTemplate from '../Common/faq-template';
import PageNotFound from '../Common/PageNotFound';
import Section from '../Section/Section';
import { Helmet } from 'react-helmet';
import InfographicsTemplate from '../Common/InfographicsTemplate';
import '../../components/loader.css';
import PreLoad from '../Common/preload';
import CommonFunc from '../../assets/js/Components/CommonFunc';

function Experience(props)
 {
    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);
    const [size, setSize] = useState([0, 0]);
    const fetchData = async () => 
    {
        let pageName = CommonObj.PageNameList.Home;
        console.log(JSON.stringify(pageName));
        console.log(CommonObj.Endpoints.GetPage);
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify( pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
    }
  function PageScroller()
  {  
    const timer = setTimeout(() => 
    {
      setLoading(false);
    }, 3000);
  }
    useEffect(() => {
        fetchData();
        PageScroller();
    },[]);

    useEffect(() => {
        if (page != null) {
            heroCarousel();
            faq();
            formInit();
            parallax();
            scrollRefresh();
        }
        hidePreLoad();
        resizeWindow();
    },[page]);

    const parse = require("html-react-parser");
    if (page != null) {
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
        let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
        
        require('html-react-parser');
        return (
           <>
           {loading ?  (<PreLoad></PreLoad>) : (
           <Section PageName={page.name} async>
                <Helmet>
                    <title>{page.title}</title>
                    <meta name="description" content={page.metaDescription} />
                    <meta name="keywords" content={page.metaKeywords} />
                </Helmet>
                             <Parallax pageheroimage={page.image ? page.image : ''} ></Parallax>
                <Hero landingpage={false} heading1={page.heading} desc={page.shortDesc} carouselList={heroCarouselList} resources={props.resources}></Hero>

                <div className="container-mb bg-gray">
                    <section className="container">
                        <div className="inner">
                            <h2 style={{ padding: '7vw' }}>{page.longDesc && parse(page.longDesc)}</h2>
                        </div>
                    </section>
                </div>
                {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={props.resources}></TestimonialExcerptSection>}
                {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} resources={props.resources}></TestimonialTemplate>}
                {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={false} resources={props.resources}></BlogTemplate>}
                {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={props.resources}></FAQTemplate>}
                </Section>
           )}
                </>
        )
    }
    else {
        return(
            <>
            <PreLoad></PreLoad>
            {/* {loading ?  (<PreLoad></PreLoad>) : (
                <PageNotFound></PageNotFound>
            )} */}
            </>
            )
    }
}


export default Experience;
import React from "react";
import IconTemplate from "../Common/icon-template";
import StaticResourceText from "../Format/static-resource-text";
const DesignCreativeIconSection = ({ icons, resources }) => {
  require("html-react-parser");

  return (
    <div className="parallax-container icons-section">
      <section className="container">
        <div className="list-container">
          <h2 className="align-center center-content mt0 mb0">
            <StaticResourceText
              resources={resources}
              reskey={"GBS-DSG_CREAT-ICONS-TITLE"}
            ></StaticResourceText>
          </h2>
          {!!icons.length && (
            <IconTemplate icons={icons} resources={resources}>
              {" "}
            </IconTemplate>
          )}
        </div>
      </section>
    </div>
  );
};
export default DesignCreativeIconSection;

export default function videoInit( ){

     const homeVideo = document.getElementById('video_home');
     const videoIconHome = document.querySelector('.video__icon--home');
     if(!homeVideo) return null;
     // console.log(homeVideoControls)

     homeVideo.onplay = function(){
          videoIconHome.classList.add('hide');
          this.controls = true 
     }
     homeVideo.onpause = function(){
          videoIconHome.classList.remove('hide');
          this.controls = false
     }
     homeVideo.onended = function(){
          videoIconHome.classList.remove('hide');
          this.controls = false
     }

     videoIconHome.addEventListener('click', function(e){
          e.preventDefault();
          homeVideo.play()
          homeVideo.focus();

          
     }, false);

     homeVideo.addEventListener('click', function(e){
          e.preventDefault();
          if(this.paused){
               homeVideo.play()
               homeVideo.focus();
          } else {
               homeVideo.pause();
               videoIconHome.focus();
          }
          
     }, false);
}
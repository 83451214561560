import React, {  } from "react";

const IconTemplate = ({ icons }) => {
  const parse = require("html-react-parser");

  return (
    <div className="icons__holder mb0 mt0">
      {icons &&
        icons.map((icon) => {
          return (
            <div className="icons__content" key={icon.title + "-icon"} async>
              <img src={icon.image} alt={icon.title} />
              <div className="align-center">
                {icon.shortDesc && parse(icon.shortDesc)}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default IconTemplate;

import React from "react";
import Design from "../Icons/Design";
import Parallax from "../Common/Parallax";

const PresentationServicesPanelSection = ({ panels, resources, button, isNoExploreSection }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");
  let right = false;
  return (
    <>
      {panels &&
        panels.map((panel) => {
          return (
            <div
              className={"subpagesection sub_sub_section " + (isNoExploreSection ? "" : "explore_section")}
              key={panel.title}
            >
              <Parallax banner3={panel.image ? panel.image : ""}></Parallax>
              <div className={"design__element " + (right ? "right" : "")}>
                <Design></Design>
              </div>
              <section className="container flow">
                <h2 className="color-white">{panel.title}</h2>
                {panel.shortDesc && parse(panel.shortDesc)}
              </section>
            </div>
          );
        })}
    </>
  );
};
export default PresentationServicesPanelSection;

import React from "react";

const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 69">
      <g>
        <circle
          cx="34.25"
          cy="34.25"
          r="33.5"
          fill="none"
          strokeLinecap="round"
        />
        <line
          x1="20.59"
          y1="34.63"
          x2="48.03"
          y2="34.63"
          fill="none"
          strokeLinecap="round"
        />
        <path
          d="M35.7,21.75h0A31.25,31.25,0,0,0,46.64,33.41l2,1.22-1.74,1A29,29,0,0,0,36.27,47h0"
          fill="none"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
export default Arrow;

import React from 'react';

const MenuArrow = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="8.828" viewBox="0 0 14.828 8.828">
                <path d="M747.074,298.086l6,6,6-6" transform="translate(-745.66 -296.672)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
            </svg>
        </>
    )
}
export default MenuArrow
import React, { useEffect } from "react";
import VideoPopUp from "../../assets/js/Components/VideoPopUpTemplate";

const VideoIconTemplate = ({ videos, resources }) => {
  const parse = require("html-react-parser");
  useEffect(() => {
    VideoPopUp();
  }, []);

  function removespace(str) {
    return str.split(" ").join("").toLowerCase();
  }
  return (
    <div async className="mt-0">
      <div className="icons__holder mb0 mt0">
        {videos &&
          videos.map((video) => {
            return (
              <div className="icons__content" key={video.title}>
                <img src={video.image} alt={video.title} />
                <div className="align-center">
                  {!!video.shortDesc && parse(video.shortDesc)}
                </div>
                <a
                  className="video_play_link"
                  data-target={removespace(parse(video.title))}
                >
                  <img src="/assets/img/SVG/video_icon.svg" alt="Play icon" />
                  View sample
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default VideoIconTemplate;

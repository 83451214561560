import React from "react";
import Parallax from "../Common/Parallax";
const RBAPanelSection = ({ panels, resources }) => {
  /* NOSONAR */

  return (
    <button id="youtubeTrigger" async>
      {panels &&
        panels.map((panel) => {
          return (
            <div className="subpagesection sub_sub_section" key={panel.title}>
              <Parallax banner3={panel.image ? panel.image : ""}></Parallax>
              <img
                src="/assets/img/col_imgoverlay.svg"
                alt="vectorimage"
                role="presentation"
                className="absimg"
              />
              <section className="container flow">
                <h2 className="color-white" style={{ textAlign: "left" }}>
                  {panel.title}
                </h2>
                <p className="ch50 color-white">{panel.shortDesc}</p>
              </section>
            </div>
          );
        })}
    </button>
  );
};
export default RBAPanelSection;

import React from 'react';
import IconTemplate from '../Common/icon-template';
import StaticResourceText from '../Format/static-resource-text';
const GBS_PS_3DSolutionsIconSection = ({ icons, resources }) => {


    return (
        <div className="parallax-container" async>
            <section className="container">
                <div className="list-container three_d_icon_content">
                    <StaticResourceText resources={resources} reskey={'GBS-PAS-3DSOL-PAGE-ICONS1-TITLE'}></StaticResourceText>
                    {!!icons.length && <IconTemplate icons={icons} resources={resources}> </IconTemplate>}
                </div>
            </section>
        </div>
    )
}
export default GBS_PS_3DSolutionsIconSection
import React from "react";
import StaticResourceText from "../Format/static-resource-text";

const OfferSection1 = ({ resources }) => {
  /* NOSONAR */
  return (
    <div className="section__pink">
      <section className="container">
        <StaticResourceText
          resources={resources}
          reskey={"GBS-PRESENTATION-OFFER-BUTTON-1"}
        ></StaticResourceText>
      </section>
    </div>
  );
};
export default OfferSection1;

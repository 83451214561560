function addRRDGoCreativeJsonLd() {
  return {
    __html: `{

        "@context": "https://schema.org",
        "@type": "Corporation",
        "legalName": "RRD Go Creative",
        "description": "We help you make Customer Experience your competitive differentiator by reimagining customer interactions, and how your customers connect with your brand.",
        "url": "https://gocreative.rrd.com/",

"address":
[
  {
  "@type" : "PostalAddress",
          "streetAddress" : "Prestige Polygon, 471, Anna Salai, Nandanam",
          "addressLocality" : "Chennai",
          "addressRegion" : "Tamil Nadu",
          "addressCountry" : "India",
          "postalCode" : "600035"
  },
  {
   "@type" : "PostalAddress",
          "streetAddress" : "Thejaswini, 6th Fl, Technopark",
          "addressLocality" : "Kayavattom",
          "addressRegion" : "Trivandrum",
          "addressCountry" : "India",
          "postalCode" : "695 581"
  },
  {
   "@type" : "PostalAddress",
          "streetAddress" : "Level 33, E. Tower, World Trade Centre",
          "addressLocality" : "Kayavattom",
          "addressRegion" : "Colombo",
          "addressCountry" : "Sri Lanka",
          "postalCode" : "01"

  },
{
   "@type" : "PostalAddress",
          "streetAddress" : "26th Flr, Robinsons Cybergate 3, Pioneer Street",
          "addressLocality" : "Mandaluyong City",
          "addressRegion" : "Manila",
          "addressCountry" : "Philippines",
          "postalCode" : "1550"
  }
,
{
   "@type" : "PostalAddress",
          "streetAddress" : "16745 SE Kens Court, Suite I",
          "addressLocality" : "Portland",
          "addressRegion" : "Orlando",
          "addressCountry" : "United States",
          "postalCode" : "97267"
  }

,
{
   "@type" : "PostalAddress",
          "streetAddress" : "BMO Tower 1850 N. Central Ave, Ste 500",
          "addressLocality" : "Phoenix",
          "addressRegion" : "Arizona",
          "addressCountry" : "United States",
          "postalCode" : "85004"
  }
,
{
   "@type" : "PostalAddress",
          "streetAddress" : "730 Peachtree N.E. Street",
          "addressLocality" : "Atlanta",
          "addressRegion" : "Georgia",
          "addressCountry" : "United States",
          "postalCode" : "30308"
  },
{
   "@type" : "PostalAddress",
          "streetAddress" : "261 Madison, Suite 1400",
          "addressLocality" : "New York",
          "addressRegion" : "New York",
          "addressCountry" : "United States",
          "postalCode" : "10016"
  }

,
{
   "@type" : "PostalAddress",
          "streetAddress" : "18780 W. 78th St",
          "addressLocality" : "Chanhassen",
          "addressRegion" : "Minnesota",
          "addressCountry" : "United States",
          "postalCode" : "55317"
  }

]
    
    },
        "logo": "https://www.rrd.com/img/about/brand/rrd-logo-circle.svg",
        "image" : "https://www.rrd.com/img/home/rrd-home-720.jpg",
        "sameAs" : [
          "https://www.linkedin.com/company/rrd-globaloutsourcingsolutions/",
          "https://www.instagram.com/rrdgocreative_showcase/",
          "https://www.youtube.com/c/RRDGOCreative",
"https://twitter.com/rrdonnelley", 
"https://www.facebook.com/RRDGOCreative"
          ]
  }`,
  };
}

export default addRRDGoCreativeJsonLd;

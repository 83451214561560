import React, { useState, useEffect } from 'react';
import CommonObj from '../../../CommonObj';
import heroCarousel from '../../../../assets/js/Components/heroCarousel';
import carousel from '../../../../assets/js/Components/carousel';
import faq from '../../../../assets/js/Components/faq';
import { collaspeMenu } from '../../../../assets/js/Components/navigation';
import hidePreLoad from '../../../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../../../assets/js/Components/parallax';
import resizeWindow from '../../../../assets/js/Components/resizeWindow';
import videoInit from '../../../../assets/js/Components/video';
import formInit from '../../../../assets/js/Components/formInit';
import Parallax from '../../../Common/Parallax';
import Hero from '../../../Common/Hero';
import TestimonialExcerptSection from '../../../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../../../Common/TestimonialTemplate';
import BlogTemplate from '../../../Common/blog-template';
import FAQTemplate from '../../../Common/faq-template';
import PageNotFound from '../../../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../../../Section/Section';
import PanelTemplate from '../../../Common/panel-template';
import InfographicsTemplate from '../../../Common/InfographicsTemplate';
import DesignCreativeIconSection from '../../DesignCreativeIconSection';
import RemediAdIconLinkSection2 from "../../RemediAdIconLinkSection2";
import RemediAdIconSection from "../../RemediAdIconSection";
import PanelSubSubSection from "../../../Common/panel-sub-subsection";
import { CircleLoader } from 'react-spinners';
import '../../../../components/loader.css'
import PreLoad from '../../../Common/preload';
import CommonFunc from '../../../../assets/js/Components/CommonFunc';
import StaticResourceText from '../../../../components/Format/static-resource-text'
import GOC_Services from "../../../../components/global-brand-solutions/GOC_Services";
import GOC_Parrallex_container from '../../../../components/global-brand-solutions/GOC_Parrallex_container';
import ThumbnailView from "../../../../components/global-brand-solutions/ThumbnailView";



function ImmersiveAndInteractive({ resources }) {
    
    const [page, setPage] = useState();
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState([0, 0]);
  const fetchData = async () => {
    let pageName = CommonObj.PageNameList.ImmersiveAndInteractive;
    console.log(JSON.stringify(pageName));
    console.log(CommonObj.Endpoints.GetPage);
    let res = await fetch(CommonObj.Endpoints.GetPage, {
      method: 'POST',
      body: JSON.stringify(pageName),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let page = await res.json()
    setPage(page);
    CommonFunc(page);
  }
  function PageScroller()
  {
    const timer = setTimeout(() => 
    {
      setLoading(false);
    }, 3000);
  }
  useEffect(() => {
    // fetch call used to be here
    fetchData();
    collaspeMenu();
    PageScroller();
  }, []);

  useEffect(() => {
    if (page != null) {
      heroCarousel();
      carousel();
      faq();
      formInit();
      parallax();
      videoInit();
    }
    window.scrollTo(0, 0);
    hidePreLoad();
    resizeWindow();
  }, [loading]);

    if (page != null) {
      let thumbnails = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails
      );
      let heroCarouselList = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Carousels
      );
      let faqs = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.FAQS
      );
      let blogs = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Blogs
      );
      let panels = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Panels
      );
      let infographics = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Infographics
      );
      let testimonials = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Testimonials
      );
      let casestudies = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.CaseStudies
      );
      const parse = require("html-react-parser");
      let serviceicons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "Industries_We_Serve"
      );
  
      let toolsIcons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_TOOLS"
      );
      let webIcons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_WEB"
      );
      let virtualIcons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_VIRTUAL"
      );
      let augmentedIcons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_AUGMENTED"
      );
      let SolutonthumbnailIcons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_THUMBNAIL_SOLUTION"
      );
      let Domaincons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_DOMAINS"
      );
      let solutionIcons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_SOLUTION"
      );
      let OfferIcons = page.contents.filter(
        (x) => x.type == CommonObj.ContentType.Thumbnails && x.tags == "IMMERSIVE_INTERACTIVE_OFER"
      );
  
      return (
        <Section PageName={page.name}>
          <Helmet>
            <title>{page.title}</title>
            <meta name="description" content={page.metaDescription} />
            <link rel="canonical" href={process.env.baseDomainUrl} />
          </Helmet>
          <Parallax pageheroimage={page.image ? page.image : ""}></Parallax>
          <Parallax
            pageFixedImage={page.backgroundImage ? page.backgroundImage : ""}
          ></Parallax>
          <Hero
            landingpage={true}
            heading1={page.shortDesc && parse(page.shortDesc)}
            carouselList={heroCarouselList}
            resources={resources}
          ></Hero>
          <div className="gray">
            <section className="container">
              <div className="inner">
                <div className="center-content para90">
                  {page.longDesc && parse(page.longDesc)}
                  {!!infographics.length && (
                    <InfographicsTemplate
                      infographics={infographics}
                    ></InfographicsTemplate>
                  )}
                </div>
              </div>
            </section>
          </div>
          <div className="bg-white">
            <section className="container fourcol_cards " id="presentation_services">
  
              <StaticResourceText
                resources={resources}
                reskey={"IMMERSIVE_INTERACTIVE_ENDTOEND"}
              ></StaticResourceText>
              <div className="holder-thumbnail mt-50">
                {!!SolutonthumbnailIcons.length && (
                  <GOC_Services
                    icons={SolutonthumbnailIcons}
                    resources={resources}
                    resourceKey={"IMMERSIVE_INTERACTIVE_THUMBNAIL_SOLUTION"}
                    background={{ backgroundColor: "#fff" }}
                  ></GOC_Services>
                )}
  
              </div>
            </section>
          </div>
          {<GOC_Parrallex_container
            resources={resources}
            resourceKey={"IMMERSIVE_INTERACTIVE_DEEPDIVE"}
            Icon1={Domaincons}
            Icon2={solutionIcons}
            Icon3={OfferIcons}
          ></GOC_Parrallex_container>}
          <StaticResourceText
            resources={resources}
            reskey={"IMMERSIVE_INTERACTIVE_CONTACTUS"}
          ></StaticResourceText>
          <div className="parallax-container bg-white">
            <section className="container" id="presentation_services">
              {!!serviceicons.length && (
                <ThumbnailView
                  icons={serviceicons}
                  resources={resources}
                  resourceKey={"ImmersiveAndInteractive-THUMBNAILS-TITLE"}
                  background={{ backgroundColor: "#fff" }}
                ></ThumbnailView>
              )}
              <StaticResourceText
                resources={resources}
                reskey={"IMMERSIVE_INTERACTIVE_PLATFORMS"}
              ></StaticResourceText>
              <div className="pt0">
  
  
  
                {!!augmentedIcons.length && (
                  <ThumbnailView
                    icons={augmentedIcons}
                    resources={resources}
                    resourceKey={"IMMERSIVE_INTERACTIVE_AUGMENTED"}
                    background={{ backgroundColor: "#fff" }}
                  ></ThumbnailView>
                )}
                {!!virtualIcons.length && (
                  <ThumbnailView
                    icons={virtualIcons}
                    resources={resources}
                    resourceKey={"IMMERSIVE_INTERACTIVE_VIRTUAL"}
                    background={{ backgroundColor: "#fff" }}
                  ></ThumbnailView>
                )}
                {!!webIcons.length && (
                  <ThumbnailView
                    icons={webIcons}
                    resources={resources}
                    resourceKey={"IMMERSIVE_INTERACTIVE_WEB"}
                    background={{ backgroundColor: "#fff" }}
                  ></ThumbnailView>
                )}
                {!!toolsIcons.length && (
                  <ThumbnailView
                    icons={toolsIcons}
                    resources={resources}
                    resourceKey={"IMMERSIVE_INTERACTIVE_TOOLS"}
                    background={{ backgroundColor: "#fff" }}
                  ></ThumbnailView>
                )}
  
  
              </div>
            </section>
          </div>
          <StaticResourceText
            resources={resources}
            reskey={"IMMERSIVE_INTERACTIVE_CONTACTUS"}
          ></StaticResourceText>
          {!!testimonials.length && (
            <TestimonialExcerptSection
              testimonials={testimonials}
              resources={resources}
            ></TestimonialExcerptSection>
          )}
          {!!casestudies.length && (
            <TestimonialTemplate
              testimonials={casestudies}
              resources={resources}
            ></TestimonialTemplate>
          )}
          {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={false} resources={resources}></BlogTemplate>}
          {!!faqs.length && (
            <FAQTemplate
              faqs={faqs}
              notopspace={true}
              resources={resources}
            ></FAQTemplate>
          )}
        </Section>
      );
    } 
    else {
      return (

        <>
        <PreLoad></PreLoad>
            {/* {loading ? (<PreLoad></PreLoad>) : (
                <PageNotFound></PageNotFound>
            )} */}
        </>
    )
    }
  }
  
  export default ImmersiveAndInteractive;
  
import { gsap } from "gsap";

export default function hidePreLoad() {

    const preload = document.querySelector('.preload')
    if(!preload) /* NOSONAR */ return;
    const introTl = gsap.timeline();
    introTl
        .to(preload, {
            duration: 1.5,
            opacity: 0, ease: 'power4.inOut', onComplete: () => {
                if (preload) {
                    //&& preload.parentElement) {
                    //preload.parentElement.removeChild(preload);
                    preload.style.display = "none";
                }
            }
        });
}
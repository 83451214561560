import React from 'react';

const LearnMore = ({ className, buttonText, link }) => {
    return (
        <>
            <a rel="noopener noreferrer" href={link} className={'learnMore ' + className}>
                <div className="btn__text">{buttonText}</div>
            </a>
        </>
    )
}
export default LearnMore
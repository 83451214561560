import React, { useEffect,useState } from 'react';
import BlogTemplate from '../../components/Common/blog-template';
import FAQTemplate from '../../components/Common/faq-template';
import Hero from '../Common/Hero';
import InfographicsTemplate from '../../components/Common/InfographicsTemplate';
import PageNotFound from '../../components/Common/PageNotFound';
import Parallax from '../../components/Common/Parallax';
import TestimonialExcerptSection from '../../components/Common/TestimonialExcerptSection';
import TestimonialTemplate from '../../components/Common/TestimonialTemplate';
import CommonObj from '../CommonObj';
import InsuranceServicesIconSection from '../../components/Smart-work-solutions/insuranceservices-icon-section';
import InsuranceServicesPanelSection from '../../components/Smart-work-solutions/insuranceservices-panel-section';
import carousel from '../../assets/js/Components/heroCarousel';
import faq from '../../assets/js/Components/faq';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../assets/js/Components/parallax';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import LifeInsuranceIcons from '../../components/life-insurance/LifeInsuranceIcons';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import CommonFunc from '../../assets/js/Components/CommonFunc';
import PreLoad from '../Common/preload';


function SWS_OT_InsuranceServices({ resources }) {

    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.GeneralInsurance;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
    }
    function PageScroller()
    {
      const timer = setTimeout(() => 
      {
        setLoading(false);
      }, 3000);
    }

    useEffect(() => {
        // fetch call used to be here
       fetchData();
       PageScroller();
    }, []);
    useEffect(() => {
        if (page != null) {
            heroCarousel();
            carousel();
            faq();
            parallax();
            scrollRefresh();
        }
        window.scrollTo(0, 0);
        hidePreLoad();
        resizeWindow();
    },[loading]);
    if (page != null) 
    {
        let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        const parse = require('html-react-parser');
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        let panels = page.contents.filter(x => x.type == CommonObj.ContentType.Panels);
        let thumbnails = page.contents.filter(x => x.type == CommonObj.ContentType.Thumbnails);
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        let icons = page.contents.filter(x => x.type == CommonObj.ContentType.Icons);

        return (
            <>
            {loading ? (<PreLoad></PreLoad>) : (
            <Section PageName={page.name} async>
                <Helmet>
                    <title>{page.title}</title>
                    <meta name="description" content={page.metaDescription} />
                    <meta name="keywords" content={page.metaKeywords} />
                    {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
                </Helmet>
                <Parallax pageheroimage={page.image ? page.image : ''} ></Parallax>
                <Hero landingpage={false} category={page.heading} heading1={page.shortDesc} carouselList={heroCarouselList} resources={resources}></Hero>
                <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ''} ></Parallax>
                <div className="bg-white">
                    <section className="container">
                        <div className="inner">
                            <div className="center-content para90">
                                {page.longDesc && parse(page.longDesc)}
                                {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                            </div>
                        </div>
                    </section>
                </div>
                {!!icons.length &&<LifeInsuranceIcons resources={resources} icons={icons} title={"GEN-INSURANCE-ICONS-TITLE"}></LifeInsuranceIcons>}
                {!!thumbnails.length && <InsuranceServicesIconSection icons={thumbnails} resources={resources} isLifeInsurance={true} resourceKey={"GEN-INSURANCE-THUMB-TITLE"}></InsuranceServicesIconSection>}
                {!!panels.length && <InsuranceServicesPanelSection panels={panels}></InsuranceServicesPanelSection>}
                {!!testimonials.filter(testimonial => !testimonial.title.includes("client")).length && <TestimonialExcerptSection testimonials={testimonials.filter(testimonial => !testimonial.title.includes("client"))} resources={resources}></TestimonialExcerptSection>}
                {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} notopspace={true} resources={resources}></TestimonialTemplate>}
                {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={true} resources={resources}></BlogTemplate>}
                {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={resources}></FAQTemplate>}
            </Section>
            )}
            </>
        )
    }
    else {
        return (
            <>
            <PreLoad></PreLoad>
                {/* {loading ? (<PreLoad></PreLoad>) : (
                    <PageNotFound></PageNotFound>
                )} */}
            </>
        )
    }
}


export default SWS_OT_InsuranceServices;
import React from "react";
import Design from "../Icons/Design";
import Parallax from "../Common/Parallax";
const REITPanelSection = ({ panels }) => {
  const parse = require("html-react-parser");

  return (
    <div async>
      {panels &&
        panels.map((panel) => {
          return (
            <div
              className="subpagesection sub_sub_section explore_section"
              key={panel.title}
            >
              <Parallax banner3={panel.image ? panel.image : ""}></Parallax>
              <div className="design__element ">
                <Design></Design>
              </div>
              <section className="container flow">
                <h2 className="color-white">{panel.title}</h2>
                {panel.shortDesc && parse(panel.shortDesc)}
              </section>
            </div>
          );
        })}
    </div>
  );
};
export default REITPanelSection;

import React, { useEffect, useState } from "react";
import StaticResourceText from "../Format/static-resource-text";
const REITIconLinkSection = ({ icons, resources }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <div className="bg-white" async>
      <section className="container">
        <div className="list-container--nospace three_d_icon_content">
          <StaticResourceText
            resources={resources}
            reskey={"SWS-OT-REIT-ICON2-TITLE"}
          ></StaticResourceText>
          <div className="icons__holder mb0 mt0">
            {icons &&
              icons.map((icon, i) => {
                return (
                  <div className="icons__content" key={icon.title}>
                    <img src={icon.image} alt={icon.title} />
                    <p className="align-center">
                      <>{domLoaded && icon.shortDesc && parse(icon.shortDesc)}</>
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default REITIconLinkSection;

import React from "react";
import Plus from "../Icons/Plus";
import StaticResourceText from "../Format/static-resource-text";

const FAQTemplate = ({ faqs, notopspace, resources }) => {
  const parse = require("html-react-parser");
  return (
    <div className="bg-gray" async>
      <section className={"container " + (notopspace ? "pt0" : "")}>
        {/* ***********************************************************
         ********* Section Title & Desktop Viewmore button *********
         *********************************************************** */}
        <div className="container__title">
          <h2 className="align-center">
            <StaticResourceText
              resources={resources}
              reskey={"FAQS-TITLE"}
            ></StaticResourceText>
          </h2>
        </div>

        {/* ***********************************************************
         ********* Section Faqs *********
         *********************************************************** */}
        <div className="holder-faq">
          {faqs.length &&
            faqs.map((faq, i) => {
              return (
                <div className="faq" key={faq.title}>
                  <div
                    className="faq__header"
                    rel="noopener noreferrer"
                    href="#"
                  >
                    <div className="faq__title">
                      <h3>{faq.title && parse(faq.title)}</h3>
                    </div>
                    <button className="faq__icon" title="faq trigger">
                      <Plus></Plus>
                    </button>
                  </div>
                  <div className="faq__content" async>
                    {faq.shortDesc && parse(faq.shortDesc)}
                  </div>
                </div>
              );
            })}
        </div>
        {/* ***********************************************************
         ********* small devices viewmore button *******************
         *********************************************************** */}
      </section>
    </div>
  );
};
export default FAQTemplate;

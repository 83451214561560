import configJson from   '../../Config.json';
function OGImage(props) 
{
        let urlRoot = configJson.baseUrl;

    return <>
        <meta property="og:image" content={urlRoot + '/assets/img/rrd_logo_url.png'}/>
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300"></meta>
        <meta name="Referrer-Policy" value="no-referrer | same-origin"/>
    </>
}
export default OGImage;
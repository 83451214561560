import { gsap } from "gsap/dist/gsap";
import Draggable from "gsap/dist/Draggable";

export default function mapInteractive() {
     gsap.registerPlugin(Draggable);

     const locationPopup = document.querySelector('.location__popup');
     if (!locationPopup) { return false };

     let groupParentElement;

     const locationPopupTl = gsap.timeline({ paused: true })
     locationPopupTl
          .from(locationPopup, { duration: 1, autoAlpha: 0, ease: 'power4.out' })
          .from('.location__details.active', { duration: 1, autoAlpha: 0, ease: 'power4.out' })


     locationPopup.addEventListener('click', (e) => {
          if (e.target.classList.contains('location__popup')) {
               locationPopupTl.reverse(0.3).then(() => {
                    document.querySelector('.location__details.active').classList.remove('active');
                    groupParentElement.classList.remove('active')
               })

          }
     })

     const locationGroups = Array.from(document.querySelectorAll('.worldmap__holder svg > g > g:first-child'))

     locationGroups.forEach(location => {
          location.addEventListener('click', (e) => {
               document.querySelector(`[data-target="${location.id}"]`).classList.add('active');
               locationPopupTl.play();

               groupParentElement = location.parentElement;
               groupParentElement.classList.add('active');
          })
     })

     const draggableMap = new Draggable.create("#globemap", {
          type: "x",
          bounds: '.worldmap__holder',
          inertia: true,
          activeCursor: "grabbing",
          autoScroll: 1,
          edgeResistance: 0.5,
     });

     function mapMove() {
          const _distance = window.innerWidth - draggableMap[0].target.clientWidth;
          return _distance;
     }

     console.log(mapMove())


     function dragEnable() {
          if (window.innerWidth <= 768 && document.querySelectorAll("#globemap").length > 0 ) {
               draggableMap[0].enable()
               gsap.to("#globemap", { x: mapMove() / 10 });

               // gsap.set('#globemap', {xPercent: 50})

          } else {
               draggableMap[0].disable()
               gsap.to('#globemap', { clearProps: "all" })
          }
     }

     dragEnable();
     window.addEventListener('resize', dragEnable);


}
class Utils {
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getPreviewQuery(isPreview) {
    return isPreview ? "?q=preview" : "";
}
}
const utils = new Utils();
export default utils;

/* eslint-disable react/jsx-key */
import React from "react";

const parse = require("html-react-parser");
const GOC_Services = ({ icons, resources, resourceKey, background }) => {
  /* NOSONAR */

  return (
   
   
     icons && icons.map((icon) => {
          return (
            <div className="thumbnail">
            <div>
           
             <div className="align-left thumbnail__icon">
                 <img src={ icon.image} alt={icon.title}/>
             </div>
             <div className="align-left color-primary mt-20">
             <strong>{!!icon.title && parse(icon.title)}</strong>
             </div>
            
            <div className="align-left thumbnail__title flow mt-20">
                {!!icon.shortDesc && parse(icon.shortDesc)}
          </div>
          </div>
        </div>
          )
        })
        
  );
};


export default GOC_Services;

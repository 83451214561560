import React from "react";
const PDCustomerOutcome = () => {
  /* NOSONAR */

  return (
    <div className="bg-white">
      <section className="container">
        <div className="inner">
          <div className="center-content para90">
            <h2 className="align-center center-content mt0 mb0">
              Customer Outcomes for Centralized Marketing Stack
            </h2>
            <p className="align-center">
              Marketers who have adopted a single, centralized marketing data
              stack can achieve at least two significant benefits:
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default PDCustomerOutcome;

import React from "react";
import Design from "../Icons/Design";
import Parallax from "./Parallax";
const PanelSubSubSection = ({ panels }) => {
  let right = false;
  const parse = require("html-react-parser");
  return (
    <div>
      {panels.map((panel) => {
          /* NOSONAR */
          return (
            <div className="subpagesection sub_sub_section" key={panel.title}>
              <Parallax banner3={panel.image ? panel.image : ""}></Parallax>
              <div className={"design__element " + (right ? "right" : "")}>
                <Design></Design>
              </div>
              <section className="container flow">
                <h2 className="color-white">{panel.title}</h2>
                {panel.shortDesc && parse(panel.shortDesc)}
              </section>
            </div>
          );
        })}
    </div>
  );
};
export default PanelSubSubSection;

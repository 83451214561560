import React from 'react'

const Section = (props) => {
let PageName= "Divcls_"+props.PageName?.replaceAll(' ', '-');;

  return (
    <div className= {'sectionStyle '+PageName} >
        {props.children}
    </div>
  )
}

export default Section

import { gsap } from "gsap";
import { smootherStop } from "./parallax";
import Modernizr from '../vendor/modernizr-custom.js'
let mobilePopupTl = gsap.timeline({
  paused: true,
});

export default function formInit() {
  // const formContainer = document.querySelector(".form__container");
  // // if form is not available below stmt return and skip further execution.

  // // input label active function
  // const inputFields = formContainer.querySelectorAll("input, textarea");

  // inputFields.forEach((_input) => {
  //   _input.onblur = function () {
  //     _input.parentElement.classList.remove("active");
  //   };
  //   _input.onfocus = function () {
  //     _input.parentElement.classList.add("active");
  //   };
  // });
  const contactusWrapper = document.querySelectorAll(".contactus-wrapper");
 
  // const formSmallTrigger = document.querySelector(".form-small-trigger");
  // const formTrigger = document.querySelector(".form-trigger");
  // const formTrigger1 = document.querySelector(".form-trigger-1");
  // const formTrigger2 = document.querySelector(".form-trigger-2");
  // const formTrigger3 = document.querySelector(".form-trigger-3");

  // const mobilePopup = document.querySelector("#mobilepopup");
  // const popupElements = Array.from(
  //   document.querySelectorAll(
  //     "#mobilepopup .form__inner, #mobilepopup .mobile__close"
  //   )
  // );

  // mobilePopupTl
  //   .from(mobilePopup, { duration: 1.5, xPercent: 110, ease: "power3.out" }, 0)
  //   .from(
  //     popupElements,
  //     {
  //       duration: 0.75,
  //       xPercent: 10,
  //       opacity: 0,
  //       autoAlpha: 0,
  //       ease: "power4.inOut",
  //       stagger: 0.1,
  //     },
  //     0
  //   );

  // popup open function
  // function smallFormTriggerfn() {
  //   [formSmallTrigger, formTrigger, formTrigger1, formTrigger2, formTrigger3].forEach(ele =>{
  //     if(!ele) return;
  //     ele.addEventListener("click", (e) => {
  //       e.preventDefault();
  //       mobilePopup.classList.add("active");
  //       mobilePopupTl.play().then(() => {
  //         document.getElementById("name").focus();
  //       });
  //       document.body.style.overflow = "hidden";
  
  //       if (!Modernizr.touchevents) {
  //         smootherStop();
  //       }
  //     });
  //   } );
  // }
  function BindContactusClickfn()
  {
    contactusWrapper.forEach((contactForm) => {
      contactForm.addEventListener("click", (e) => {
      e.preventDefault();
      if(window.location.pathname =="/global-brand-solutions/creative-production/presentation-services")
      {
          localStorage.setItem("isPresentationService", true);
      }
      else{
        localStorage.setItem("isPresentationService", false);
      }
      window.location = '/contact-us';
    })
  });
  }
  BindContactusClickfn();
  //smallFormTriggerfn();

  // Popup close by click event
//   const mobileClose = document.querySelector(".contact-mobile-close");

//   mobilePopup.addEventListener(
//     "click",
//     function (e) {
//       if (e.target.tagName === "ASIDE") {
//         mobilePopupTl.reverse().then(() => {
//           this.classList.remove("active");
//           document.body.style.overflow = "auto";
//           formSmallTrigger.focus();
//           if (!Modernizr.touchevents) {
//             smootherStop();
//           }
//         });
//       }
//     },
//     false
//   );

//   const closeContactPopup = () => {


//     mobilePopupTl.reverse().then(() => {
//       mobileClose.classList.remove("active");
//       document.body.style.overflow = "auto";
//       formSmallTrigger.focus();

//       if (!Modernizr.touchevents) {
//         smootherStop();
//       }
//     });
//   };

//   mobileClose.addEventListener("click", closeContactPopup, false);
// 
}

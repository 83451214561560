import React from "react";
import IconTemplate from "../Common/icon-template";
import StaticResourceText from "../Format/static-resource-text";
const RBAIconSection = ({ icons, resources }) => {
  return (
    <div className="parallax-container icons-section">
      <section className="container">
        <div className="list-container">
          <div className="flow">
            <StaticResourceText
              resources={resources}
              reskey={"SWS-RBA-ICONS-TITLE"}
            ></StaticResourceText>
          </div>
          {!!icons.length && (
            <IconTemplate icons={icons} resources={resources}>
            </IconTemplate>
          )}
        </div>
      </section>
    </div>
  );
};
export default RBAIconSection;

import React from 'react';
import VideoIconTemplate from '../Common/video-icon-template';
import StaticResourceText from '../Format/static-resource-text';
const PresentationServicesIconSection = ({ videos, resources }) => {

    return (
        <>
            <div className="parallax-container icons-section presentation">
                <section className="container">
                    <div className="list-container">
                        <h2 className="align-center center-content mt0 mb0"><StaticResourceText resources={resources} reskey={'GBS-PAS-PS-ICONS1-TITLE'}></StaticResourceText></h2>
                        {!!videos.length && <VideoIconTemplate videos={videos} resources={resources}> </VideoIconTemplate>}
                    </div>
                </section>
            </div>
        </>
    )
}
export default PresentationServicesIconSection
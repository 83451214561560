import React from "react";
import Design from "../Icons/Design";
import Parallax from "../Common/Parallax";
const UtilitiesPanelSection = ({ panels, isNotExplore,isUtilExplore }) => {
  /* NOSONAR */

  const parse = require("html-react-parser");

  return (
    <>
      {panels &&
        panels.map((panel) => {
          /* NOSONAR */
          return (
            <div
              className={
                "subpagesection sub_sub_section " +
                (isNotExplore ? "" : "explore_section")+
                (isUtilExplore ? " panel__holder mt-0" : "" )
              }
              key={panel.title}
            >
              <Parallax banner3={panel.image ? panel.image : ""}></Parallax>
              <div className={"design__element "+ (panel.direction == "Right" ? "right" : "")} >
                <Design></Design>
              </div>
              <section className= { (isUtilExplore ? " container panel" : "container flow" ) } async>
              <div className={(panel.direction == "Right" ? "right " : "") + " inner"} style={{textAlign : (isUtilExplore == true ? " left" : "") }}>
                <h2 className="color-white">{panel.title}</h2>
                {panel.shortDesc && parse(panel.shortDesc)}
                </div>
              </section>
            </div>
          );
        })}
    </>
  );
};
export default UtilitiesPanelSection;

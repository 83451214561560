import React from "react";
import Plus from "../Icons/Plus";

const YoutubePopUp = ({video}) => {
  return (
    <aside
      id="youtubepopup"
      className="popup_container"
      aria-label="Youtube contact popup"
    >
      <div className="iframe_container">
        <video id="retirement_video" controls controlsList="nodownload">
          <source type="video/mp4" src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentVideo/10636/rrd_retirement.mp4" />
          <track label="English" kind="captions" srcLang="en" default src="/assets/videos/rrd_retirement.vtt"/>
        </video>
      </div>
      <button className="mobile__close youtube-mobile-close">
        <span className="close__dummy">close button</span>
        <Plus></Plus>
      </button>
    </aside>
  );
};
export default YoutubePopUp;

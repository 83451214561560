import React, { useEffect } from "react";
import addRRDGoCreativeJsonLd from "../rrdgocreativejsonld";
import OGImage from '../Common/OgImage';
import { Helmet } from "react-helmet";


const Header = (props) => {
return (
    <>
    <div async>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="Referrer-Policy" value="no-referrer | same-origin" />
      
        <OGImage />

        {/* <meta httpEquiv="Content-Security-Policy" content="default-src  'self' *.rrd.com; connect-src *.rrd.com https://www.google-analytics.com https://www.googletagmanager.com https://www.google.com/recaptcha https://dev-gocreativecmsexternalapi.rrd.com; script-src 'unsafe-inline' 'self' 'nonce-GoCMSWebNAUSGHDV' *.rrd.com https://www.google-analytics.com https://www.googletagmanager.com https://www.google.com/recaptcha; style-src *.rrd.com 'self' 'unsafe-inline' https://fonts.googleapis.com https://www.google.com https://fonts.gstatic.com; base-uri *.rrd.com; object-src *.rrd.com *.amazonaws.com; img-src  'self' *.rrd.com *.amazonaws.com  data: https:; font-src 'self' *.rrd.com https://fonts.gstatic.com data: https:; frame-src www.google.com; media-src 'self' data: https: *.amazonaws.com;" ></meta> */}

        {/* <meta httpEquiv="Content-Security-Policy" content="default-src 'self' 'unsafe-eval' 'unsafe-inline'; connect-src 'self' https://www.google-analytics.com https://www.googletagmanager.com https://www.google.com/recaptcha https://localhost:7101; script-src 'unsafe-inline' 'unsafe-eval' 'self' 'nonce-GoCMSWebNAUSGHDV'; style-src 'self' 'unsafe-inline' https://fonts.googleapis.com https://www.google.com https://fonts.gstatic.com https://localhost:7101; base-uri 'self'; object-src 'self'; img-src 'self' https://gorrd-prod-gocms-s3-data.s3.amazonaws.com  data: https:; font-src 'self' https://fonts.gstatic.com data: http: https:; frame-src www.google.com;"></meta> */}
        <script nonce="GoCMSWebNAUSGHDV" type="application/ld+json" dangerouslySetInnerHTML={addRRDGoCreativeJsonLd()} key="rrdgocreative-jsonld"/>
        <link rel="canonical" href="https://gocreative.rrd.com/"></link>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@400;500;600&display=swap" rel="stylesheet" />

          <link rel="stylesheet" href="/assets/css/app.css" />
          <link rel="stylesheet" href="/assets/vendor/swiper/swiper-bundle.min.css" />
          <link rel="shortcut icon" href="/assets/img/favicon.ico"type="image/vnd.microsoft.icon"/>
          <title>RRD GO Creative</title>

            <script  src="../../assets/vendor/swiper/swiper-bundle.min.js" defer></script>
            <script type="text/jsx" nonce="GoCMSWebNAUSGHDV"src="../../assets/vendor/jquery.min.js" defer/>
            <script type="text/jsx"  nonce="GoCMSWebNAUSGHDV" src="../../assets/vendor/util.js"defer/>
            <script type="text/jsx" nonce="GoCMSWebNAUSGHDV" src="../../assets/vendor/contact.js"defer/>
            <script  nonce="GoCMSWebNAUSGHDV" src="https://www.google.com/recaptcha/api.js"defer></script>
            <script nonce="GoCMSWebNAUSGHDV" src="https://www.google.com/recaptcha/api.js?render=explicit" async defer></script>
            <script type="text/jsx" nonce="GoCMSWebNAUSGHDV"src="../../assets/vendor/modernizr-custom.js" defer/>
      </Helmet>
      </div>
     
    </>
  );
 }
 export default Header

import React, { useEffect, useState } from "react";
import Parallax from "./Parallax";

const PanelTemplate = ({ panels }) => {
  const parse = require("html-react-parser");
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  let i = 0;
  return (
    <div className="full-width">
      {panels != undefined &&
        panels.map((panel) => {
          /* NOSONAR */
          return panel.direction === "Left" ? (
            <>
              <div className="col2 p_left" key={panel.title + "pannel_" + i}>
                <div async>
                  <h3>{domLoaded && panel.title && parse(panel.title)}</h3>
                  {domLoaded && panel.shortDesc && parse(panel.shortDesc)}
                </div>
              </div>
              <div className="col2 imgbg1">
                <Parallax
                  pagePanelBgImage1={panel.image ? panel.image : ""}
                ></Parallax>
                <div className="bg bg1"></div>
                <div className="colimg_container">
                  <img src="/assets/img/col_imgoverlay.svg" alt="vectorimage" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col2 imgbg2" key={panel.title + "pannel_" + i}>
                <Parallax
                  pagePanelBgImage2={panel.image ? panel.image : ""}
                ></Parallax>
                <div className="bg bg2"></div>
                <div className="colimg_container flexend">
                  <img
                    className=" flip"
                    src="/assets/img/col_imgoverlay.svg"
                    alt="vectorimage"
                  />
                </div>
              </div>

              <div className="col2 p_right">
                <div async>
                  <h3>{domLoaded && panel.title && parse(panel.title)}</h3>
                  {domLoaded && panel.shortDesc && parse(panel.shortDesc)}
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default PanelTemplate;

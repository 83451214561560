
import React, { useEffect,useState } from "react";
import CSHero from "../Common/cs-hero";
import PageNotFound from "../Common/PageNotFound";
import Parallax from "../Common/Parallax";
import CommonObj from "../CommonObj";
import Arrow from "../Icons/Arrow";
import hidePreLoad from "../../assets/js/Components/hidePreLoad";
import {mobileMenu,navigationInit} from "../../assets/js/Components/navigation";
import parallax, {scrollRefresh} from "../../assets/js/Components/parallax";
import resizeWindow from "../../assets/js/Components/resizeWindow";
import { Helmet } from "react-helmet";
import Section from '../Section/Section';
import {useParams} from "react-router-dom";
import PreLoad from "../Common/preload";

export default function CaseStudy({  resources }) 
{ /* NOSONAR */
  const parse = require("html-react-parser");
  const {PageName} = useParams();
  const [page, setPage] = useState();
    const fetchData = async () => 
    {
        let pageName = "/"+PageName;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify( pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
    }
    
    useEffect(() => {
        // fetch call used to be here
          fetchData();
    },[]);

  useEffect(() => {
    if (page && page.caseStudy != null) {
      mobileMenu();
    }
    parallax();
    scrollRefresh();

    window.scrollTo(0, 0);
    hidePreLoad();
    resizeWindow();
  },[page]);
  if (page && page.caseStudy != null) {
    return (
      <>
      <Section PageName={page.name} async>
        <Helmet>
          <title>{page.caseStudy && page.caseStudy.title}</title>
          <meta name="description" content={page.metaDescription} />
          {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
        </Helmet>
        <Parallax csImage={page.image ? page.image : ""}></Parallax>
        <CSHero
          heading1={page.caseStudy ? page.caseStudy.shortDesc : ""}
          csDate={page.caseStudy ? page.caseStudy.date : ""}
          resources={resources}
        ></CSHero>

        <div className="blogs-page">
          <section className="container">
            <div className="inner maxwidth900">
              {page.caseStudy && parse(page.caseStudy.longDesc)}
              <div className="link-back">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    window.history.back();
                    //window.location.href = localStorage.getItem("ancestor");
                    return false;
                  }}
                  className="btn {{className}}"
                >
                  <div className="btn__icon">
                    <Arrow></Arrow>
                  </div>
                  <div className="btn__text">back</div>
                </a>
              </div>
            </div>
          </section>
        </div>
        </Section>
      </>
    );
  }
   else {
    return <PreLoad></PreLoad>;
  }
}



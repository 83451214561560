import React from "react";
import Design from "../Icons/Design";
import Anchor from "../Icons/Anchor";

const IndexPanel = ({ panels }) => {
  /* NOSONAR */
  return (
    <div className="panels__wrapper">
      {panels.length &&
        panels.map((panel, i) => {
          return (
            <div
              className={"panel" + (i + 1) + " panel__holder clip-animate"}
              key={panel.title}
              style={{
                backgroundImage: `url(${panel.image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: `cover`,
                backgroundPosition: "left center",
              }}
            >
              <div className="mobilebg"></div>
              <div
                className={
                  "design__element " +
                  (panel.direction == "Right" ? "right" : "")
                }
              >
                <Design></Design>
              </div>
              <section className="container panel">
                <div
                  className={
                    (panel.direction == "Right" ? "right" : "") + " inner"
                  }
                >
                  <h2 className="color-white bold">{panel.title}</h2>
                  <p className="color-white">
                    {panel.shortDesc.replace(" &amp; ", " & ")}
                  </p>

                  <Anchor
                    className="btn__secondary"
                    anchorText="LEARN MORE"
                    icon="true"
                    link={panel.link}
                  ></Anchor>
                </div>
              </section>
            </div>
          );
        })}
    </div>
  );
};
export default IndexPanel;

import React from "react";
import StaticResourceText from "../Format/static-resource-text";
import IconTemplate from "../Common/icon-template";
const REIT_AccountingServicesIconSection = ({ icons, resources }) => {
  /* NOSONAR */

  return (
    <div className="parallax-container">
      <section className="container">
        <div className="list-container three_d_icon_content">
          <h2 className="align-center center-content mt0 mb0">
            <StaticResourceText
              resources={resources}
              reskey={"SWS-OT-REIT-ICON1-TITLE"}
            ></StaticResourceText>
          </h2>
          <p className="align-center line-effect">
            <StaticResourceText
              resources={resources}
              reskey={"SWS-OT-REIT-ICON1-HEADER1"}
            ></StaticResourceText>
          </p>
          <div>
            {icons.length && (
              <IconTemplate
                icons={icons.filter((icon) =>
                  icon.tags && icon.tags.toString().includes("SWS-OT-REIT-ICON1-HEADER1")
                )}
              ></IconTemplate>
            )}
          </div>
          <p className="align-center line-effect">
            <StaticResourceText
              resources={resources}
              reskey={"SWS-OT-REIT-ICON1-HEADER2"}
            ></StaticResourceText>
          </p>
          <div>
            {icons.length && (
              <IconTemplate
                icons={icons.filter((icon) =>
                  icon.tags && icon.tags.toString().includes("SWS-OT-REIT-ICON1-HEADER2")
                )}
              ></IconTemplate>
            )}
          </div>
          <p className="align-center line-effect">
            <StaticResourceText
              resources={resources}
              reskey={"SWS-OT-REIT-ICON1-HEADER3"}
            ></StaticResourceText>
          </p>
          <div>
            {icons.length && (
              <IconTemplate
                icons={icons.filter((icon) =>
                  icon.tags && icon.tags.toString().includes("SWS-OT-REIT-ICON1-HEADER3")
                )}
              ></IconTemplate>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
export default REIT_AccountingServicesIconSection;

import React from "react";

const IndexVideo = ({videos}) => {
  return (
    <>
      {videos &&
        videos.map((video) => {
          return (
            <div className="container-mt home__video bg-white" key={video}>
              <section className="container video">
                <div className="inner">
                  <button className="video__icon video__icon--home">
                    <img
                      src="/assets/img/SVG/video_icon.svg"
                      alt="Video play button"
                    />
                  </button>
                  <div className="video__element">
                    <video
                      id="video_home"
                      controlsList="nodownload"
                      poster={video.image}
                    >
                      <source
                        type="video/mp4"
                        src={video.video}
                      />
                      <track
                        label="English"
                        kind="captions"
                        srcLang="en"
                        default
                        // src={video.documentPath}
                        src="/assets/videos/rrds-goc-en.vtt"
                      />
                    </video>
                  </div>
                </div>
              </section>
            </div>
          );
        })}
    </>
  );
};
export default IndexVideo;

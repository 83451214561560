import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";
import IconTemplate from '../Common/icon-template';
const IASIconSection = ({ icons, resources }) => {
  /* NOSONAR */

  return (
    <div className="parallax-container" async>
      <section className="container">
        <div className="list-container">
          <div className="align-center center-content mt0 mb0">
            {
              <StaticResourceText
                resources={resources}
                reskey={"TECH_INNOV-IAS-ICONS-TITLE"}
              ></StaticResourceText>
            }
          </div>
          {icons.length && <IconTemplate icons={icons}></IconTemplate>}
        </div>
      </section>
    </div>
  );
};
export default IASIconSection;

import {gsap} from 'gsap'

export default () => {
     const slides = gsap.utils.toArray('.heroSlider')
     if(slides){

          // triggers
          const triggers = gsap.utils.toArray('.ctrigger')

          // Main timelin
          const carouselTl = gsap.timeline({
               repeat:-1,
          });


          for (let i = 0; i < slides.length; i++) {
               const element = slides[i];
               carouselTl.add(childcarouselTl(element, i));
               carouselTl.addLabel('hc' + i);
               carouselTl.add(childcarouselTlLeave(element, i));
          }


          // enter animation
          function childcarouselTl(el, i){
               const elementEnterLabel = 'elementEnter' + i
               return gsap.timeline({
                    delay:-0.6
               })
               .addLabel('elementEnter' + i)
               .from(el, {duration:1, autoAlpha:0, yPercent:5, ease:'power4.inOut'}, elementEnterLabel)
               .to(triggers[i], {backgroundColor:'white', duration:0.1}, elementEnterLabel)

          }
          
          // exit animation
          function childcarouselTlLeave(el, i){
               
               return gsap.timeline({
                    delay:3
               })
               .to(el, {duration:0.5, autoAlpha:0, yPercent:5, ease:'power3.out'})
               .to(triggers[i], {backgroundColor:'transparent'})

     
          }

          // trigger click event
          triggers.forEach(trigger => {

               trigger.addEventListener('mouseenter', ()=> {
                    carouselTl.pause();
               })

               trigger.addEventListener('mouseleave', ()=> {
                    carouselTl.play();
               })


               trigger.addEventListener('click', function(){
                    const target = this.getAttribute('data-hc');
                    gsap.to('.herocarousel__holder',{duration:1, opacity:0, yPercent:5}).then(() => {
                         carouselTl.seek('hc' + target );
                    }).then(() => {
                         gsap.to('.herocarousel__holder',{duration:1, opacity:1, yPercent:0});
                    })

               })
          })

     }

     
}
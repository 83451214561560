import React from "react";
const PDBlueBanner = () => {
  /* NOSONAR */

  return (
    <div className="section__blue section__blue__bg">
      <section className="container">
        <div className="inner para90">
          <h2>The best tools for each task</h2>
          <p>
            Choose the technology applications that address your specific needs
            rather than relying on one-size-fits-all platforms which may not
            offer the right suite of solutions. You can build your own marketing
            stack on a modern cloud data platform.
          </p>
          <h2>Unified data</h2>
          <p>
            With a modern cloud data platform as the foundation of the Modern
            Marketing Data Stack, marketers can experience near-unlimited scale,
            performance, and flexibility when storing, integrating, analyzing,
            and sharing datasets of any volume, format, or velocity. It can also
            unlock all sorts of collaboration internally and externally,
            providing organizations with a far more open ecosystem of potential
            data partners, data service providers, tools, and applications.
          </p>
        </div>
      </section>
    </div>
  );
};
export default PDBlueBanner;
